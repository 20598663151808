<template>
  <div @click="back" :style="styles" :class="classes">
    <slot>
      <div :class="innerClasses">
        <van-icon name="arrow-up" />
      </div>
    </slot>
  </div>
</template>

<script>
import {scrollTop, on, off} from '@utils/assist';
const prefixCls = 'ivu-back-top';
export default {
  props: {
    hasTabbar: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number,
      default: 400
    },
    bottom: {
      type: Number,
      default: 30
    },
    right: {
      type: Number,
      default: 30
    },
    duration: {
      type: Number,
      default: 1000
    }
  },
  data() {
    return {
      backTop: false
    };
  },
  mounted() {
    on(window, 'scroll', this.handleScroll);
    on(window, 'resize', this.handleScroll);
  },
  beforeDestroy() {
    off(window, 'scroll', this.handleScroll);
    off(window, 'resize', this.handleScroll);
  },
  computed: {
    classes() {
      return [`${prefixCls}`,{[`${prefixCls}-show`]: this.backTop}];
    },
    styles() {
      if(this.hasTabbar) {
        return {
          bottom: `calc(${this.bottom}px + constant(safe-area-inset-bottom))`,
          bottom: `calc(${this.bottom}px + env(safe-area-inset-bottom))`,
          right: `${this.right}px`
        }
      }
      return {bottom: `${this.bottom}px`, right: `${this.right}px`};
    },
    innerClasses() {
      return `${prefixCls}-inner`;
    }
  },
  methods: {
    handleScroll() {
      this.backTop = window.pageYOffset >= this.height;
    },
    back() {
      const sTop = document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop(window, sTop, 0, this.duration);
      this.$emit('on-click');
    }
  }
}
</script>

<style lang="less" scoped>
.ivu-back-top {
  z-index: 10;
  position: fixed;
  display: none
}

.ivu-back-top.ivu-back-top-show {
  display: block
}

.ivu-back-top-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  background-color: rgba(@c_deep_rgba, .1);
  border-radius: 2px;
  // -webkit-box-shadow: 0 1px 3px rgba(255,255,255, .05);
  // box-shadow: 0 1px 3px rgba(255,255,255, .05);
  -webkit-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out
}

.ivu-back-top i {
  display: block;
  color: #fff;
  font-size: 24px;
  padding: 8px 12px
}
</style>