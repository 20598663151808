<template>
  <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
    <div id="home">
      <div class="header">
        <!-- 搜索 -->
        <div class="search">
          <van-sticky class="sticky-search">
            <div class="search">
              <div class="search-cont">
                <div class='search-wrap' @click="toSearch">
                  <p class="iconfont icon_search"></p>
                  <div class="search-swiper">
                    <van-swipe vertical loop :autoplay="5000" :show-indicators="false">
                      <!-- 跨境好服务 -->
                      <van-swipe-item>{{language.l_1}}</van-swipe-item>
                      <!-- 臻品永不停 -->
                      <van-swipe-item>{{language.l_2}}</van-swipe-item>
                    </van-swipe>
                  </div>
                </div>
                <div v-if="isLogin" @click="toUserInfo" class='search-user'>
                  <img :src="avatar" alt="">
                </div>
                <!-- 登录 -->
                <div v-else @click="toLogin" class='search-user'>{{language.l_3}}</div>
              </div>
            </div>
          </van-sticky>
        </div>

        <!-- 轮播 -->
        <div class="swiper">
          <van-swipe :autoplay="5000" :style="{height: vwHeight}" indicator-color="white" id="swiper">
            <van-swipe-item v-for="(item, index) in swiperList" :key="index">
              <van-image :src="item.url" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
      <div class="container">
        <!-- 热销排行 -->
        <div v-if="hot_goods.length" class="ranking">
          <van-swipe :autoplay="5000" :show-indicators="false" >
            <van-swipe-item v-for="(item, index) in hot_goods" :key="index">
              <div v-for="(detail, i) in item" :key="i" @click.stop="toGoodsInfo(detail.id)" class="ranking_img"><van-image lazy-load fit="contain" :src="detail.picture" /></div>
            </van-swipe-item>
          </van-swipe>
        </div>

        <!-- 猜你喜欢 -->
        <div class="guess_like">
          <!-- 推荐 -->
          <p class="common-p"><span class="common-title"><span class="iconfont icon_tl"></span>{{language.l_4}}<span class="iconfont icon_tr"> </span></span></p>
          <HomeShops :data="guess_like"/>
        </div>
      </div>

      <!-- 回到顶部 -->
      <BackTop :bottom="100" :right="10" :hasTabbar="true"/>
    </div>
  </van-pull-refresh>
</template>

<script>
import HomeShops from '@cpt/HomeShops';
import BackTop from '@cpt/BackTop';
import {getIndex} from '@api'

export default {
  data () {
    return {
      height: '160',  //轮播图高度
      refreshing: false, //下拉刷新
      swiperList: [],  //轮播图
      hot_goods: [],  //热销排行
      guess_like: [],  //推荐
      avatar: "require('@img/home/user_avatar.png')",  //用户信息
      language: this.$store.getters.language
    }
  },
  components: {
    HomeShops,
    BackTop
  },
  activated(){
    if(this.$store.state.userInfo) {this.avatar = this.$store.state.userInfo.avatar}
  },
  created() {
    this.getData()
  },
  methods:{
    onRefresh() {  // 刷新页面
      setTimeout(() => {
        this.refreshing = false
        this.getData()
      }, 1000);
    },
    toSearch() {  //跳转搜索页面
      this.$router.push({name: 'Search'})
    },
    toUserInfo() {  //跳转个人中心
      this.$router.push({name: 'UserInfo'})
    },
    toLogin() {  //跳转登录页面
      this.$router.push({name: 'Login'})
    },
    toGoodsInfo(id) {  //跳转商品详情
      this.$router.push({name: 'GoodsInfo',params: {id}})
    },
    getData() {
      getIndex().then(res => {
        const {advertise, hot_goods, guess_like} = res.data
        this.swiperList = advertise
        this.hot_goods = hot_goods
        this.guess_like = guess_like
      })
    }
  },
  computed: {
    vwHeight() {  //计算轮播图高度
      const viewportWidth = 375 / 100;
      return this.height / viewportWidth + 'vw'
    },
    isLogin() {return this.$store.state.token}
  }
}
</script>

<style lang='less' scoped>
#home {
  position: relative;
  background-color: #fff;
  .safe_padding_bottom(50px);
  .common-p {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    font-size: 14px;
    color: #000;
    overflow: hidden;
    .common-title {
      font-weight: bold;
      margin: 0 auto;
    }
    .common-msg {
      margin: 0 20px;
    }
  }
  .header {
    width: 100%;
    height: 160px;
    background-image: @grad_top;
    border-radius: 0 0 10px 10px;
    &::before {
      content: '';
      display: table;
    }
    .sticky-search {
      .search {
        height: 36px;
        padding: 10px 0;
        .search-cont {
          display: flex;
          align-items: center;
          transition: all ease .2s;
          box-sizing: border-box;
          .search-wrap {
            flex: 1;
            display: flex;
            /* align-items: center; */
            box-sizing: border-box;
            padding: 5px 10px 5px 0;
            margin-left: 10px;
            height: 34px;
            border-radius: 6px;
            background-color: #fff;
            overflow: hidden;
            .icon_search {
              display: flex;
              align-items: center;
              box-sizing: border-box;
              padding-left: 15px;
              // margin-top: 2px;
              margin-right: 5px;
              font-size: 14px;
              color: #323233;
            }
            .search-swiper {
              flex: 1;
              height: 100%;
              .van-swipe {
                width: 100%;
                height: 100%;
                .van-swipe-item {
                  display: flex;
                  align-items: center;
                  color: #999;
                  font-size: 14px;
                }
              }
            }
          }
          .search-user {
            display: flex;
            align-items: center;
            padding: 0 10px;
            font-size: 14px;
            color: #fff;
            img {
              display: block;
              width: 25px;
              height: 25px;
              border-radius: 50%;
            }
          }
        }
      }

      .van-sticky--fixed {
        .search {
          padding: 5px 0;
          background-color: @c_deep;
        }
      }
    }

    .swiper {
      width: calc(100% - 20px);
      margin: 0 auto;
      .van-swipe {
        border-radius: 10px;
        overflow: hidden;
        .van-image {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      /deep/ .van-swipe__indicator {
        background-color: #999;
      }
    }
  }

  .container {
    box-sizing: border-box;
    padding: 0 10px;
    .ranking {
      box-sizing: border-box;
      width: 100%;
      height: 135px;
      padding: 40px 5px 5px;
      margin-top: 67px;
      background-image: url('https://resource.meifanco.com/20210329/2a3f1c4b71b152fbebfeb3f95f37cefb.png');
      background-repeat: no-repeat;
      background-size: calc(257px / 2 ) 25px;
      background-position: 50% -1px;
      border: 6px solid @c_deep;
      .van-swipe {
        width: 100%;
        height: 100%;
        .van-swipe-item {
          display: flex;
          justify-content: left;
          .ranking_img {
            box-sizing: border-box;
            width: 100px;
            height: 80px;
            padding: 15px 25px;
            margin: 0 5px;
            background-image: url('https://resource.meifanco.com/20201028/6a1eeb6ffcc158320986c4e9ed3e9a5a.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            .van-image {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }

    .guess_like {
      margin-top: 10px;
      .icon_tl,
      .icon_tr {
        .grandient_color(@grad_top);
        margin: 0 10px;
      }

      .tab {
        padding: 0;
        /deep/ .tab-wrap {
          box-shadow: 0 0 5px rgba(0, 0, 0, .05);
        }
      }
    }
  }
}
</style>