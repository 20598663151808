<template>
  <div class="tab">
    <div v-for="(item, index) in data" :key="index" @click="toGoodsInfo(item.id)" class="tab-wrap">
      <div class="tab-top">
        <van-image :src="item.pics.length > 0 ? item.pics[0].url : ''" fit="contain" lazy-load/>
        <p class="tab-text van-multi-ellipsis--l2">{{item.title}}</p>
      </div>
      <div class="tab-content">
        <div class="tab-left">
          $<span class="tab-price">{{item.sale_price}}</span>
        </div>
        <div class="tab-right">...</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array
  },
  methods: {
    toGoodsInfo(id) { // 跳转商品详情
      this.$router.push({name: 'GoodsInfo', params: {id}})
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 10px auto 0;
  box-sizing: border-box;
  .tab-wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 156px;
    height: 240px;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 8px;
    margin-bottom: 10px;
    .tab-top {
      .van-image {
        display: block;
        width: 100%;
        height: 156px;
      }
      .tab-text {
        font-size: 14px;
        line-height: 1.5;
      }
    }
    
    .tab-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      .tab-left {
        display: flex;
        align-items: center;
        color: red;
        font-size: 12px;
        .tab-price {
          font-size: 14px;
          font-weight: bold;
        }
        .tab-active {
          display: flex;
          align-items: center;
          font-size: 10px;
          height: 14px;
          margin-left: 5px;
          span {
            text-align: center;
            width: 30px;
          }
          .van-hairline--surround::after {
            border-color: red;
            border-radius: 15px;
          }
        }
      }
      .tab-right {
        margin-top: -9px;
      }
    }
  }
}
</style>